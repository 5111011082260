import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Ant from 'ant-design-vue'
import VXETable from 'vxe-table'
import ElementPlus from 'element-plus'

import 'element-plus/dist/index.css'
import vueEsign from 'vue-esign'
import moment from 'moment'; 
import 'moment/locale/zh-cn';



moment.locale('zh-cn');

import './assets/css/index.less'
import 'xe-utils'
import 'vxe-table/lib/style.css'
import 'lib-flexible/flexible'
import 'ant-design-vue/dist/antd.css';

// router.beforeEach((to, from, next) => {
//     const role = sessionStorage.getItem("TOKEN")
  
//     if(to.path === '/login'){
//       sessionStorage.removeItem('TOKEN');
//       sessionStorage.removeItem("MENUINDEX");
//     }
  
//     if (!role && to.path !== '/login') {
//       next('/login');
//     } else {
//       next()
//     }
//   })
createApp(App).use(router).use(store).use(Ant).use(ElementPlus).use(VXETable).use(vueEsign).mount('#app')
