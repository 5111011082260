import {createRouter,createWebHashHistory} from 'vue-router'

const routes = [
    {
        path:'/',
        redirect: '/login'
    },
    {
        path:'/login',
        name:'login',
        component:() => import ('../page/LoginPage.vue')
    },
    {
        path:'/laints',
        name:'laints',
        component:()=>import ('../view/CompLaints.vue'),
    },
    {
        path:'/Register',
        name:'Register',
        
        component:()=>import ('../view/ReflectParticulars/RegisterPage.vue'),
    },
    {
        path:'/ReflectPutIn',
        name:'ReflectPutIn',
        component:()=>import ('../view/ReflectParticulars/ReflectPutIn.vue'),
    },
    {
        path:'/SearchResult',
        name:'SearchResult',
        component:()=>import ('../view/ReflectParticulars/SearchResult.vue'),
    },
    {
        path:'/DimissionPage',//离职页面
        name:'DimissionPage',
        component:()=>import('@/components/DimissionPage.vue')
    },
    {
        path:'/successView',//离职页面
        name:'successView',
        component:()=>import('@/view/dimission/successView.vue')
    },
    {
        path:'/main',
        component:() => import ('../page/IndexPage.vue'),
        children:[
            {
                path:'',
                component:() => import ('../view/HomePage.vue'),
            },
            {
                path:'home',
                name:'home',
                component:() => import ('../view/HomePage.vue'),
            },
            {
                path:'attend',
                name:'attend',
                component:() => import ('../view/AttendRecord.vue'),
            },
            {
                path:'workbench',
                name:'workbench',
                component:() => import ('../view/WorkBench.vue'),
            },
            {
                path:'message',
                name:'message',
                component:() => import ('../view/MessageNotifi.vue'),
            },
            {
                path:'briefing',
                name:'briefing',
                component:() => import ('../view/WorkBriefing.vue'),
            },
            {
                path:'training',
                name:'training',
                component:() => import ('../view/TrainingManage.vue'),
            },
            {
                path:'express',
                name:'express',
                component:() => import ('../view/workbench/ExpressPage.vue'),
            },
            {
                path:'opinion',
                name:'opinion',
                component:() => import ('../view/workbench/OpinionPoll.vue'),
            },
            {
                path:'dimission',
                name:'dimission',
                component:()=>import('../view/workbench/ExitInterview.vue'),
            },
            {
                path:'budget',
                name:'budget',
                component:() => import ('../view/workbench/BudgetPage.vue'),
            },
            {
                path:'reimbursement',
                name:'reimbursement',
                component:() => import ('../view/workbench/ReimberPage.vue'),
            },
        ]
    },
]
export const router = createRouter({
    history:createWebHashHistory(),
    routes
})
export default router