import { createStore } from "vuex";

export default createStore({
    state:{
        version:'',
        account:'',
        baseURL:process.env.VUE_APP_STORE ? process.env.VUE_APP_STORE : process.env.VUE_APP_BASEURL,
        token:sessionStorage.getItem('TOKEN'),
        expirationTime:''//过期时间
    },
    getters:{
        version:(state) => {
          return state.version
        },
        account:(state) => {
          return state.account
        },
        token:(state) => {
          return state.token
        },
        expirationTime:(state) => {
          return state.expirationTime
        },
    },
    mutations:{
        setVersion(state,v){
          state.version = v
        },
        setAccount(state,v){
          state.account = v
        },
        setToken(state,v){
          state.token = v
        },
        setExpirationTime(state,v){
          state.expirationTime = v
        },
    }
})